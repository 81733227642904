import React from 'react';

const Loader: React.FC<{ background?: string; loading?: boolean; overlay?: boolean }> = ({
    background = '#00000015',
    loading = false,
    overlay = true,
}) => {
    if (!loading) return null;

    const mainLoader = (
        <div className="profile-main-loader">
            <div className="logo-wrapper">
                <img className="logo" src="/white-square-logo.png" alt="Beewoo" />
            </div>
            <div className="loader">
                <svg className="circular-loader" viewBox="25 25 50 50">
                    <circle className="loader-path" cx="50" cy="50" r="20" fill="none" stroke="#fff" strokeWidth="2" />
                </svg>
            </div>
        </div>
    );

    if (overlay)
        return (
            <div
                className="loader-wrapper"
                style={{
                    background: background,
                }}
            >
                {mainLoader}
            </div>
        );
    return mainLoader;
};

export default Loader;
