import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import LazySuspense from 'components/LazySuspense';

const Admin = React.lazy(() => import('Admin'));
const LandingPage = React.lazy(() => import('LandingPage'));

const Root = () => {
    useEffect(() => {
        // Remove loader after loading all content
        const initialLoader = document.getElementById('initial-loader');

        initialLoader?.parentElement?.removeChild(initialLoader);
    }, []);

    return (
        <HelmetProvider>
            <Router>
                <Routes>
                    <Route
                        index
                        element={
                            <LazySuspense>
                                <LandingPage />
                            </LazySuspense>
                        }
                    />
                    <Route
                        path="*"
                        element={
                            <LazySuspense>
                                <Admin />
                            </LazySuspense>
                        }
                    />
                </Routes>
            </Router>
        </HelmetProvider>
    );
};

const root = createRoot(document.getElementById('root')!);
root.render(<Root />);
